// Customizable Area Start
import React from "react";
import {
  Button,
  InputLabel,
  Box,
  styled,
  Snackbar,
  TextField,
  InputAdornment,
  CircularProgress,
  Paper,
  TablePagination
} from "@material-ui/core";
import { toString } from "lodash"
import Alert from "@material-ui/lab/Alert";
import { colors } from "../../utilities/src/Colors";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import InfiniteScroll from "react-infinite-scroll-component";
import B2BPriceListProductsController, {
  Props,
  IB2BProductList,
} from "./B2BPriceListProductsController";
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
import EditIcon from "@material-ui/icons/Edit";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { renderBaseOnConditions, sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
import { StyledCheckBox } from "../../../components/src/CustomTheme.web";
import { trashIcon } from "./assets";

// Customizable Area End

export class B2BPriceListProducts extends B2BPriceListProductsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHeader = () => {
    return (
      <TableRow columns={"1.5fr 1fr"}>
        {[
          {
            title: "Product Name",
            sortingKey: "name",
          },
          {
            title: "Action",
            sortingKey: "active",
          },
        ]
          .map(
            ({ title, sortingKey }: { title: string; sortingKey?: string }) => (
              <TableCell
                header
                key={title}
                content={title}
                className={"f-16 align-item-center"}
                sortingKey={sortingKey}
                sortingIcons={[
                  IconSortingNormal,
                  IconSortingUp,
                  IconSortingDown,
                ]}
                sortingDisabled={!Boolean(sortingKey)}
                {...this.getSortingProps()}
              />
            )
          )}
      </TableRow>
    );
  };

  handleTableNullValue = (value: string | number | null) => {
    if (value) {
      return <span>{value}</span>;
    } else {
      return <>---</>;
    }
  };

  renderIcons = (data: IB2BProductList) => {
    return (
      <Box display="flex" alignItems="center" width="100%" flexWrap="wrap" justifyContent="end" gridGap={40}>
        <Box display="flex" alignItems="center" color={colors().cyancobaltblue} gridGap={8} fontWeight={600}>
          <CustomSwitch
            data-test-id="switch-product"
            checked={data.attributes.active}
            onChange={(checked) => { this.handleSwitch(checked, data.id) }}
          />
          <p>
            {renderBaseOnConditions(data.attributes.active, "Enabled", "Disabled")}
          </p>
        </Box>
        <EditIcon
          data-test-id="edit-product"
          onClick={() => this.handleOpenModal("Edit", data)}
          htmlColor="#204B9C"
        />
        <img
          data-test-id="delete-product"
          src={trashIcon}
          onClick={() => this.handleOpenModal("Delete", data)}
        />
      </Box>
    );
  };

  renderAddProduct = () => {
    const { productSelectToggle, productSelectedIds, productListDropdownSearchText } = this.state;
    const filteredProducts = this.getFilteredProducts()

    return (
      <>
        <CustomPaper className="p-24">
          <Box flexShrink={0} paddingBottom="12px">
            <StyledInputLabel
              className="text-start"
              data-test-id='lblTxtName'
            >
              Add Product
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant='outlined'
              inputProps={{
                "data-test-id": "field-product-dropdown",
              }}
              placeholder='Select Product'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <ArrowDropDownIcon />
                  </InputAdornment>
                ),
              }}
              onClick={this.handleProductSelectToggle}
              value={productListDropdownSearchText}
              onChange={(event) => this.handleSearchProduct(toString(event.target.value))}
            />
          </Box>

          {productSelectToggle && (
            <InfiniteScroll
              dataLength={filteredProducts.length}
              next={this.fetchMoreDropdownProducts}
              hasMore={
                this.state.prodDropDownNextPage < this.state.productListDropdownMeta.total_pages
              }
              loader={<></>}
              height={200}
            >
              {renderBaseOnConditions(
                filteredProducts.length > 0,
                filteredProducts.map((product, index) => (
                  <StyledProductOption
                    key={index}
                  >
                    <StyleCheckLable>{product.attributes.name}</StyleCheckLable>
                    <StyledCheckBox
                      dataTestId="product-check"
                      style={
                        sortCondition(
                          !productSelectedIds.includes(product.id),
                          {
                            border: "1px solid #64748B",
                            borderRadius: "6px",
                          },
                          {}
                        ) as React.CSSProperties
                      }
                      onClick={() =>
                        this.handleProductCheck(product.id)
                      }
                      checked={productSelectedIds.includes(product.id)}
                    />
                  </StyledProductOption>
                )),
                <>No Product Found</>
              )}
            </InfiniteScroll>
          )}
        </CustomPaper>
      </>
    );
  };

  renderEditProduct = () => {
    const { productNameError, productName } = this.state;
    return (
      <CustomPaper className="p-24">
        <Box flexShrink={0} paddingBottom="12px">
          <StyledInputLabel
            className="text-start"
            data-test-id='lblTxtName'
          >
            Name
          </StyledInputLabel>
          <StyledTextField
            fullWidth
            variant='outlined'
            inputProps={{
              "data-test-id": "field-edit",
            }}
            value={productName}
            placeholder='Product Name'
            onChange={(event) => this.handleEditChange(event)}
          />
          {productNameError && (
            <Box
              color="red"
              fontSize={14}
              margin="5px 5px 5px 0"
              fontFamily="Montserrat"
              textAlign="start"
            >
              Product name is required
            </Box>
          )}
        </Box>
      </CustomPaper>
    );
  };

  renderModalContent = () => {
    const { productModalType } = this.state;
    switch (productModalType) {
      case "Add":
        return this.renderAddProduct();
      case "Edit":
        return this.renderEditProduct();
      case "Delete":
        return <></>;
    }
  };

  renderProductModal = (isModalOpen: boolean) => {
    let confirmButtonText;
    let title;
    const { productModalType, productNameError, productAddEditLoader } = this.state;
    switch (productModalType) {
      case "Add":
        confirmButtonText = "Add";
        title = "Add Product";
        break;
      case "Edit":
        title = "Edit Product";
        confirmButtonText = "Update";
        break;
      case "Delete":
        confirmButtonText = "Delete";
        title = "Are you sure you want to delete?";
        break;
      default:
        break;
    }

    return (
      <>
        <CustomDialog
          className='main-dialog-container'
          open={isModalOpen}
          data-test-id='commonConfirmModal'
          onClose={this.handleClose}
        >
          <Box className='dialog-main'>
            <DialogTitle>{title}</DialogTitle>
            <StyledDialogContent>
              <DialogContentText>{this.renderModalContent()}</DialogContentText>
            </StyledDialogContent>
            <MainBox>
              <SecondaryButtonBox>
                <Button
                  data-test-id='cancelModelBtn'
                  aria-label='cancel-modal-button'
                  className={`secondaryButtonStyle`}
                  onClick={this.handleClose}
                  color='primary'
                >
                  Cancel
                </Button>
              </SecondaryButtonBox>
              <PrimaryButtonBox>
                <Button
                  data-test-id='confirmModelBtn'
                  aria-label='confirm-modal-button'
                  className='primaryButtonStyle'
                  color='primary'
                  autoFocus
                  disabled={productNameError || productAddEditLoader}
                  onClick={() => this.hanldeSubmit()}
                >
                  {productAddEditLoader && (
                    <CircularProgress size={20} style={{ color: "white", marginRight: 10, }} />
                  )}
                  {confirmButtonText}
                </Button>
              </PrimaryButtonBox>
            </MainBox>
          </Box>
        </CustomDialog>
      </>
    );
  };

  render() {
    const { priceListDetail, productListMeta } = this.state

    return (
      <Box>
        <CustomLoader loading={this.state.isLoading} />
        <Box>
          <StyleDiv>
            <PageTitle>View Product List</PageTitle>
          </StyleDiv>
          <CustomPaper>
            <StyleMainProductDiv>
              <StyleTitleDiv>
                <p>{priceListDetail.company_name}</p>
                <p>{priceListDetail.name}</p>
              </StyleTitleDiv>
              {
                renderBaseOnConditions(
                  Boolean(priceListDetail.id),
                  <StyledAddProdButton
                    onClick={() => this.handleOpenModal("Add")}
                    data-test-id='add-product'
                  >
                    Add Product
                  </StyledAddProdButton>,
                  null
                )
              }
            </StyleMainProductDiv>
            <StyledTableWrapper
              className="table-wrapper"
              data-test-id={"table-wrapper"}
            >
              <OrderTable>
                <RowContainer header>{this.renderHeader()}</RowContainer>
                {renderBaseOnConditions(
                  this.state.productListLoading,
                  <RowContainer>
                    <>
                      {Array(4)
                        .fill(1)
                        .map((element, index) => (
                          <RowContainer>
                            <TableRow
                              key={index}
                              className='table-rows'
                              columns='1.5fr 1fr'
                            >
                              {[...Array(2)].map((cellItem, cellIndex) => (
                                <TableRowsSkeleton key={cellIndex} />
                              ))}
                            </TableRow>
                          </RowContainer>
                        ))}
                    </>
                  </RowContainer>,
                  <>
                    {renderBaseOnConditions(
                      this.state.productList.length > 0,
                      <RowContainer>
                        {this.state.productList.map((data) => {
                          return (
                            <div>
                              <TableRow
                                key={data.id}
                                className='table-rows'
                                data-test-id='tableData'
                                columns='1.5fr 1fr'
                              >
                                {[
                                  this.handleTableNullValue(
                                    data.attributes.name
                                  ),
                                  this.renderIcons(data),
                                ].map((item, index) => (
                                  <TableCell
                                    key={`cell-${index}`}
                                    content={item as React.ReactNode}
                                    className='f-12 table-cell'
                                    type={this.getTypeOfOrderTable(index)}
                                  />
                                ))}
                              </TableRow>
                            </div>
                          );
                        })}
                      </RowContainer>,
                      <RowContainer>
                        <Box
                          className={"w-100"}
                          width="100%"
                          textAlign="center"
                          fontWeight={600}
                          fontSize={16}
                        >
                          No records found!!
                        </Box>
                      </RowContainer>
                    )}
                  </>
                )}
              </OrderTable>
            </StyledTableWrapper>
            {
              renderBaseOnConditions(
                this.state.productList.length > 0,
                <TablePagination
                  data-test-id='products-table-pagination'
                  component='div'
                  count={productListMeta.total_count}
                  page={productListMeta.current_page - 1}
                  rowsPerPage={this.itemsPerPage}
                  rowsPerPageOptions={[this.itemsPerPage]}
                  onPageChange={(_event, page: number) => this.fetchMoreProducts(page + 1)}
                />,
                null
              )
            }
          </CustomPaper>

          <StyleBox className={"actionButtonSection"}>
            <StyledCancelButton
              data-test-id='cancelBtn'
              onClick={() => this.handleRedirect()}
            >
              Back
            </StyledCancelButton>
          </StyleBox>

          {this.renderProductModal(this.state.productModalOpen)}
        </Box>
        <Snackbar
          data-test-id='snackbar'
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(this.state.snackbarMessage)}
          autoHideDuration={3000}
          onClose={this.handleSnackbarClose}
        >
          <Alert severity={this.state.snackbarServerity}>
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

const StyleDiv = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  margin: "30px 0px",
  alignItems: "center",
});

const PageTitle = styled("div")({
  fontFamily: "Montserrat",
  fontWeight: 600,
  fontStyle: "normal",
  lineHeight: "29px",
  fontSize: "24px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
});

const StyledInputLabel = styled(InputLabel)({
  fontSize: "16px",
  fontFamily: "Montserrat",
  lineHeight: "15.6px",
  fontWeight: 600,
  color: "#334155",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
  },
  "& .compensationtxt": {
    display: "inline-block",
    color: colors().cyancobaltblue,
    fontWeight: 600,
  },
  "&.text-start": {
    textAlign: "start"
  }
});

const StyledCancelButton = styled(Button)({
  height: "56px",
  width: 126,
  padding: 16,
  background: "rgb(32, 75, 156)",
  fontWeight: 600,
  fontSize: "16px",
  borderRadius: "8px",
  lineHeight: "24px",
  marginLeft: "10px",
  textTransform: "unset",
  backgroundColor: "#F1F5F9 !important",
  color: "#64748B !important",
  "@media only screen and (max-width: 1024px)": {
    height: "44px !important",
  },
});

const StyleBox = styled(Box)({
  marginTop: "48px",
  marginBottom: "40px",
  display: "flex",
  flexWrap: "wrap-reverse",
});

const StyleTitleDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  fontWeight: 500,
  fontSize: 16,
  color: colors().black,
});

const StyledAddProdButton = styled(Button)({
  height: "56px",
  width: "137px",
  background: "rgb(32, 75, 156)",
  backgroundColor: "rgb(32, 75, 156)",
  color: "rgb(255, 255, 255)",
  fontWeight: 600,
  fontSize: "16px",
  borderRadius: "8px",
  lineHeight: "24px",
  marginLeft: "10px",
  textTransform: "unset",
  cursor: "pointer",
  border: "none",
  "&:hover": {
    background: colors().cyancobaltblue,
    color: colors().background,
  },
  "@media only screen and (max-width: 1024px)": {
    height: "44px !important",
    width: "126px !important",
  },
});

const StyleMainProductDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "24px",
  padding: "0 20px"
});

const CustomDialog = styled(Dialog)({
  "@media only screen and (max-width: 1024px)": {
    fontSize: 14,
    "& .icon-section": {
      marginBottom: "15px",
    },
    "& .dialog-main": {
      width: "471px",
      margin: " 40px 20px !important",
    },
  },
});

export const StyledDialogContent = styled(DialogContent)({
  paddingTop: "0px",
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "20px",
  lineHeight: "22px",
  color: colors().darkliver,
  textAlign: "center",
  "@media only screen and (max-width: 1024px)": {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "22px",
    color: colors().viewTextColor,
    textAlign: "center",
  },
});

export const MainBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const SecondaryButtonBox = styled(Box)({
  "& .secondaryButtonStyle": {
    border: "none",
    fontSize: "16px",
    borderRadius: "8px",
    fontWeight: 700,
    cursor: "pointer",
    textTransform: "none" as "none",
    backgroundColor: colors().antiFlashWhite,
    color: colors().slateGray,
    width: 128,
    height: 56,
    marginRight: "20px",
    "@media only screen and (max-width: 1024px)": {
      width: 186,
      height: 44,
    },
  },
});

export const PrimaryButtonBox = styled(Box)({
  "& .primaryButtonStyle": {
    border: "none",
    fontSize: "16px",
    borderRadius: "8px",
    fontWeight: 700,
    cursor: "pointer",
    textTransform: "none" as "none",
    backgroundColor: colors().cyancobaltblue,
    color: colors().background,
    width: 128,
    height: 56,
    "@media only screen and (max-width: 1024px)": {
      width: 186,
      height: 44,
    },
  },
  "& .primaryButtonForConfirm": {
    border: "none",
    fontSize: "16px",
    borderRadius: "8px",
    fontWeight: 700,
    cursor: "pointer",
    textTransform: "none" as "none",
    backgroundColor: colors().cyancobaltblue,
    color: colors().background,
    width: 292,
    height: 56,
    "@media only screen and (max-width: 1024px)": {
      width: 200,
      height: 44,
    },
  },
});

const StyleCheckLable = styled("span")({
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "22px",
  color: colors().viewTextColor,
});

export const StyledTextField = styled(TextField)({
  marginTop: "10px",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "@media only screen and (max-width: 1024px)": {
    "& .MuiInputBase-input": {
      fontSize: "12px",
      padding: "14.9px 14px",
    },
  },
});

const StyledTableWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  "& > div > div > div:first-of-type": {
    background: colors().cyancobaltblue,
    borderRadius: "12px 12px 0 0",
    "& > div": {
      backgroundColor: "unset"
    }
  },
  "& .tableRow": {
    "& div:last-of-type": {
      justifyContent: "end",
    }
  },
  "& .table-cell": {
    wordBreak: "break-all",
    color: colors().viewTextColor
  }
});

export const CustomPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  background: "#fff",
  borderRadius: "12px",
  boxShadow:
    "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 5px 32px rgba(0, 0, 0, 0.06)",
  padding: "24px 0",
  "&.p-24": {
    padding: 24,
  }
});

export const StyledProductOption = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px",
  "&:nth-of-type(odd)": {
    borderRadius: 12,
    backgroundColor: colors().ghostwhite
  }
})

export default B2BPriceListProducts;
// Customizable Area End
