// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
import moment from "moment";
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
import { makeApiMessage } from "../../../components/src/common";
import { handleDisplayRegion, handleFilterCustomDate } from "./utils";
import { removeSarUnit } from "../../cfsalesdashboard3/src/utils";
import html2pdf from "html2pdf.js";
import { customPermissionApiKey, DashboardPermissionStatus, checkForDashboardPermissonStatus, checkIsFilterApplied } from "../../utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

type TotalData = {
  orders: string
  pieces: string
  amount: string
  discount: string
  subTotal: string
  vat: string
  net: string
}
type RevenueData = {
  total: string,
  unpaid: string,
  cash: string
  credit: string
  card: string
  online: string
}
type DateData = {
  date: string
}
export interface RevenueCountByYear {
  total: TotalData,
  revenue: RevenueData,
  dates: string
}

export interface OrderRevenueListRespose {
  data: Array<{
      date: string,
      order: string,
      pieces: string,
      amount: string,
      discount: string,
      subtotal: string,
      total: string,
      vat: string,
      net: string,
      revenue: string,
      unpaid: string,
      cash: string,
      credit: string,
      card: string,
      online: string,
  }>
}
export interface OrderRevenueTypeTotalResponse {
  data: {
    orders: number,
    pieces: number,
    revenue: string,
    paid: string,
    unpaid: string
}
}
export interface OrderRevenueTypeUnpaidResponse {
  data: {
    unpaid: string;
    this_year_data: string;
    next_year_data: string;
    this_month_data: string;
    this_week_date: string;
    last_two_weeks_date: string;
}
}

export interface OrderRevenueTypeRevenueResponse {
  data: {
    revenue: string;
    unpaid: string;
    cash: string;
    credit: string;
    card: string;
    online: string;
    wallet: string;
}
}

export interface OrderRevenueListResponse {
  data: Array<{
    date: string,
    order: string,
    pieces: string,
    amount: string,
    discount: string,
    subtotal: string,
    total: string,
    vat: string,
    net: string,
    revenue: string,
    unpaid: string,
    cash: string,
    credit: string,
    card: string,
    online: string
    wallet: string
  }>
}
export interface ChartData {
  name: string;
  value: number | string;
}

export const categoryColumns = [
  {
    header: "Category",
    binding: "name",
  },
  {
    header: "Order",
    binding: "order",
  },
  {
    header: "Pieces",
    binding: "pieces",
  },
  {
    header: "Amount",
    binding: "amount",
  }
] as { header: string; binding: string }[]

export const dateColumns = [
  {
    header: "Date",
    binding: "dates",
  },
] as {header: string, binding: string}[]

export const totalColumns = [
  {
    header: "Orders",
    binding: "orders",
    unit:""
  },
  {
    header: "Pieces",
    binding: "pieces",
    unit:""
  },
  {
    header: "Amount",
    binding: "amount",
    unit:"(SAR)"
  },
  {
    header: "Discount",
    binding: "discount",
    unit:"(SAR)"
  },
  {
    header: "Sub Total",
    binding: "subTotal",
    unit:"(SAR)"
  },
  {
    header: "VAT",
    binding: "vat",
    unit:"(SAR)"
  },
  {
    header: "Net",
    binding: "net",
    unit:"(SAR)"
  }
] as { header: string; binding: keyof TotalData; unit: string }[]

export const revenueColumns = [
  {
    header: "Total",
    binding: "revenue",
    unit:"(SAR)"
  },
  {
    header: "Unpaid",
    binding: "unpaid",
    unit:"(SAR)"
  },
  {
    header: "Cash",
    binding: "cash",
    unit:"(SAR)"
  },
  {
    header: "Credit",
    binding: "credit",
    unit:"(SAR)"
  },
  {
    header: "Card",
    binding: "card",
    unit:"(SAR)"
  },
  {
    header: "Online",
    binding: "online",
    unit:"(SAR)"
  },
  {
    header: "Wallet",
    binding: "wallet",
    unit:"(SAR)"
  }
] as { header: string; binding: keyof RevenueData; unit: string }[]
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: unknown;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  query: string;
  isLoading: boolean;
  sortingFields: ISortingData;
  pageNum: number;
  pageSize: number;
  textSearchHeader: string;
  filterAnchor?: HTMLDivElement;
  filters: IFilter[];
  token: string,
  printerDialogOrderId: string,
  regionId: string,
  storeId: string,
  areaId: string
  storeName: string,
  groupId: string,
  groupName: string,
  regionName: string,
  exportMethod: string,
  revenueByYears: RevenueCountByYear[],
  openExportModal: boolean,
  revenueRequestTypeTotal: ChartData[];
  revenueRequestTypeUnpaid: ChartData[];
  revenueRequestTypeUnpaidHeader: ChartData[];
  revenueRequestTypeRevenue: ChartData[];
  revenueRequestTypeRevenueHeader: ChartData[];
  isLoadingTotal: boolean;
  isLoadingUnpaid: boolean;
  isLoadingRevenue: boolean;
  permissionStatus: DashboardPermissionStatus | null;
  isAppliedFilter: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class RevenueDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  orderRevenueTotalId: string = "orderRevenueTotalId"
  orderRevenueId: string = ""
  orderRevenueUnpaidId: string = "orderRevenueUnpaidId"
  orderRevenueListId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage),
      getName(MessageEnum.LayoutDataMessage)
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      sortingFields: {
        year: "",
        clothes: "",
        carpets: "",
        orders: "",
        pcs: "",
        amount: "",
        discount: "",
        subtotal: "",
        vat: "",
        net: "",
      },
      filters: [
        {
          title: "Date",
          value: "today",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last 7 days",
              value: "last_7_days",
            },
            {
              label: "Last 30 days",
              value: "last_30_days",
            },
            {
              label: "Specific Dates",
              value: "specific",
            },
          ]
        }
      ],
      query: "",
      isLoading: false,
      textSearchHeader: "",
      pageNum: 1,
      pageSize: 10,
      filterAnchor: undefined,
      token: "",
      printerDialogOrderId: "",
      regionId: "",
      regionName: "",
      storeId: "",
      areaId: "",
      storeName: "",
      groupId: "",
      groupName: "",
      exportMethod: "",
      openExportModal: false,
      revenueByYears: [],
      revenueRequestTypeTotal: [],
      revenueRequestTypeRevenue: [],
      revenueRequestTypeUnpaid: [],
      revenueRequestTypeRevenueHeader: [],
      revenueRequestTypeUnpaidHeader: [],
      isLoadingRevenue: true,
      isLoadingTotal: true,
      isLoadingUnpaid: true,
      permissionStatus: null,
      isAppliedFilter: false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    if(this.state.regionId || this.state.storeId) {
      this.getAllApiCalling()
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    let token = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({ token: token })
    this.receiveDataFromLayout(message);
    const requestId: any = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successResponse: any = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (requestId === this.orderRevenueTotalId) {
      this.handleOrderRevenueTypeTotalResponse(successResponse);
    }
    if (requestId === this.orderRevenueUnpaidId) {
      this.handleOrderRevenueTypeUnpaidResponse(successResponse);
    }
    if (requestId === this.orderRevenueId) {
      this.handleOrderRevenueTypeRevenueResponse(successResponse);
    }
    if (requestId === this.orderRevenueListId) {
      this.handleOrderRevenueListResponse(successResponse);
    }
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const receivedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      const {areaId, storeId,groupId,regionMultiId,regionNames,regionSelectAll } = receivedData
      this.setState({areaId,storeId,groupId,regionId:regionMultiId,regionName: handleDisplayRegion(regionMultiId,regionNames,regionSelectAll).textDisplayed},()=>{
        if(regionMultiId) this.handleStorageFilter()
      })
    }
    // Customizable Area End
  }

  // Customizable Area Start

  receiveDataFromLayout = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getAllApiCalling = () => {
    this.getOrderRevenueTypeTotal()
    this.getOrderRevenueTypeUnpaid()
    this.getOrderRevenueTypeRevenue()
    this.getOrderRevenueList()
  }

  getOrderRevenueTypeTotal() {
    this.setState({
      isLoadingTotal: true
    })
    const apiEndPoint =  configJSON.orderRevenueDashboardEndPoint + "total" + "&" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.orderRevenueTotalId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleOrderRevenueTypeTotalResponse = (responseData: OrderRevenueTypeTotalResponse) => {
    const transformData: ChartData[] = [{
      name: "Orders",
      value: +responseData.data.orders
    },
    {
      name: "Pieces",
      value: responseData.data.pieces
    },
    {
      name: "Revenue",
      value:  removeSarUnit(responseData.data.revenue)
    },
    {
      name: "Paid",
      value:  removeSarUnit(responseData.data.paid)
    },
    {
      name: "Unpaid",
      value:  removeSarUnit(responseData.data.unpaid)
    }
    ]
    this.setState({ 
      revenueRequestTypeTotal: transformData,
      isLoadingTotal: false
     })
  }

  getOrderRevenueTypeUnpaid() {
    this.setState({
      isLoadingUnpaid: true
    })
    const apiEndPoint = configJSON.orderRevenueDashboardEndPoint + "unpaid" + "&" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.orderRevenueUnpaidId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOrderRevenueTypeUnpaidResponse = (responseData: OrderRevenueTypeUnpaidResponse) => {
    const transformData: ChartData[] = [{
      name: "This Week",
      value:  removeSarUnit(responseData.data.this_week_date)
    },
    {
      name: "Last Two Week",
      value:  removeSarUnit(responseData.data.last_two_weeks_date)
    },
    {
      name: "This Month",
      value:  removeSarUnit(responseData.data.this_month_data)
    },
    {
      name: "This Year",
      value:  removeSarUnit(responseData.data.this_year_data)
    },
    {
      name: "+1 Year",
      value:  removeSarUnit(responseData.data.next_year_data)
    }
    ]

    const headerData: ChartData[] = [{name: "", value: removeSarUnit(responseData.data.unpaid)}] 

    this.setState({ 
      revenueRequestTypeUnpaid: transformData, 
      revenueRequestTypeUnpaidHeader: headerData,
      isLoadingUnpaid: false
    })
  }

  getOrderRevenueTypeRevenue() {
    this.setState({
      isLoadingRevenue: true
    })
    const apiEndPoint = configJSON.orderRevenueDashboardEndPoint + "revenue" + "&" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.orderRevenueId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOrderRevenueTypeRevenueResponse = (responseData: OrderRevenueTypeRevenueResponse) => {
    const transformData: ChartData[] = [{
      name: "Unpaid",
      value:  removeSarUnit(responseData.data.unpaid)
    },
    {
      name: "Cash",
      value:  removeSarUnit(responseData.data.cash)
    },
    {
      name: "Credit",
      value:  removeSarUnit(responseData.data.credit)
    },
    {
      name: "Card",
      value:  removeSarUnit(responseData.data.card)
    },
    {
      name: "Online",
      value:  removeSarUnit(responseData.data.online)
    },
    {
      name: "Wallet",
      value:  removeSarUnit(responseData.data.wallet)
    }
    ]

    const headerData: ChartData[] = [{name: "", value: removeSarUnit(responseData.data.revenue)}] 

    this.setState({ 
      revenueRequestTypeRevenue: transformData, 
      revenueRequestTypeRevenueHeader: headerData,
      isLoadingRevenue: false
     })
  }


  getOrderRevenueList() {
    const apiEndPoint = configJSON.orderRevenueListEndPoint + "?" + this.handleDateFilterParam().params
    const requestMessage: Message = makeApiMessage({
      url: apiEndPoint,
      method: "GET",
    });
    this.orderRevenueListId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOrderRevenueListResponse = (responseData: OrderRevenueListResponse) => {
    const transformData: RevenueCountByYear[] = responseData.data.map((response) => ({
      dates: response.date,
      total: {
        amount: response.amount,
        orders: response.order,
        discount: response.discount,
        net: response.net,
        pieces: response.pieces,
        subTotal: response.subtotal,
        vat: response.vat
      },
      revenue: {
        card: response.card,
        cash: response.cash,
        credit: response.credit,
        online: response.online,
        total: response.total,
        unpaid: response.unpaid,
        revenue: response.revenue,
        wallet: response.wallet
      }
    }))
    this.setState({ revenueByYears: transformData })
  }

  handleFilterOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ filterAnchor: event.currentTarget })
  }
  handleCloseFilterPopover = () => {
    this.setState({ filterAnchor: undefined })
  }
  handleDateFilterParam = () => {
    const { storeId, regionId,groupId,areaId } = this.state
    const today = moment().format('YYYY-MM-DD');
    let startDate, endDate;
    let commonEndDate = today;
    let comomStartDate = moment().subtract(6, "days").format("YYYY-MM-DD");
    const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.value;
    let dateRange = ""

    switch (dateFilter) {
      case 'today':
        endDate = today;
        startDate = today;
        dateRange = configJSON.today
        break;
      case "last7":
      case "last_7_days":
        endDate = commonEndDate;
        startDate = comomStartDate;
        dateRange = "last_7_days"
        break;
      case "last30":
      case 'last_30_days':
        endDate = today;
        startDate = moment().subtract(29, "days").format("YYYY-MM-DD");
        dateRange = "last_30_days"
        break;
      case "specific":
          const pickedDate = handleFilterCustomDate(this.state.filters)
          startDate = moment(pickedDate.from).format("YYYY-MM-DD");
          endDate = moment(pickedDate.to).format("YYYY-MM-DD");
          dateRange = configJSON.emptyDate
          break;
      default:
        endDate = today;
        startDate = moment().format('YYYY-MM-DD');
        dateRange = "today"
    }


    const startDateText = moment(startDate).format('DD-MM-YYYY')
    const endDateText = moment(endDate).format('DD-MM-YYYY')
    const dateType = `&date_type=${Boolean(dateRange) ? dateRange : "specific_dates"}`
    const dateStartEnd = `&start_date=${startDate}&end_date=${endDate}`
    const regionParam = `region_ids=${regionId}`
    const groupParam = groupId  ? `&group_ids=${groupId}` : ""
    const areaParam = areaId  ? `&area_ids=${areaId}` : ""
    let storeParam = storeId ? `&store_ids=${storeId}` : ""
    storeParam = regionId ? storeParam + groupParam + areaParam  : ""

    const params = regionParam + storeParam + dateType + dateStartEnd
    const endDateYear = moment(endDateText, 'DD-MM-YYYY').year()

    return {
      startDateText,
      endDateText,
      params,
      regionParam,
      storeParam,
      endDateYear
    }
  };
  handleFilterChangeAccount = (filters: IFilter[]) => {
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("filter_value");
    };
    this.setState({
      filters,
      pageNum: 1,
      isAppliedFilter: checkIsFilterApplied(filters)
    }, () => {
      this.getAllApiCalling()
    });
  };

  handleUserChange = (context: IUserContext) => {
    const apiKey = customPermissionApiKey.dashboardPermission;
    const userData = context.user?.attributes.permission_groups;
    const value = checkForDashboardPermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    });
  }

  handleOpenExportModal = () => {
    this.setState({openExportModal: !this.state.openExportModal})
  }

  handleExportConfirm = async(methodName: string) => {
    if (methodName === "pdf") {
      const wrapperDomElement = document.getElementById("print-wrapper") as HTMLElement
      await html2pdf(wrapperDomElement, {
          pagebreak: { mode: ['css'] },
          filename: "Revenue-dashboard.pdf",
          margin: 24,
          jsPDF: {
              format: [1440, 1440],
              unit: "px"
          }
      })
      this.setState({ openExportModal: false })
  }
  }

  handleStorageFilter = () => {
    const applied_profession_filter = localStorage.getItem("filter_value");
    if (applied_profession_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_profession_filter),
          isAppliedFilter: checkIsFilterApplied(
            JSON.parse(applied_profession_filter)
          ),
        },
        () => {
          this.getAllApiCalling()          
        }
      );
    } else {
      this.getAllApiCalling()
    }
  };

  handleReturnColorType = () => {
    const { isAppliedFilter } = this.state;
    return isAppliedFilter ? "primary" : "inherit";
  };

  // Customizable Area End
}