// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const webConfigJSON = require("./config.js");
import { IFilterItem } from "../../promocodes/src/PromoCodeListController.web";
import { IFilter } from "../../../components/src/FilterPopover";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { makeApiMessage } from "../../../components/src/common";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import moment from "moment";
import { concat,throttle,uniqBy } from "lodash";
import { handleDisplayRegion } from "./utils";
import { CustomEnums, getCustomEnumName, customPermissionApiKey, DashboardPermissionStatus, checkForDashboardPermissonStatus, checkIsFilterApplied } from "../../utilities/src/CustomBlockHelpers";
import html2pdf from "html2pdf.js";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";

interface IMetalProgettiData {
  out: {
    order_count: number
    total_pieces: number
  };
  in: {
    order_count: number
    total_pieces: number
  }
}

interface ICardOrderDet {
  upcoming: {
    count: number;
    pieces: number;
  };
  received: {
    count: number;
    pieces: number;
  };
  loading_area: {
    count: number;
    pieces: number;
  };
  dispatched: {
    count: number;
    pieces: number;
  };
}

interface Stats {
  maxQuantity: number;
  minQuantity: number;
  maxTotalPieces: number;
  minTotalPieces: number;
}

interface ICleaningDue {
  due_today: {
    orders: number;
    pieces: number;
    completed: number;
  };
  due_tomorrow: {
    orders: number;
    pieces: number;
    completed: number;
  };
  over_due: {
    orders: number;
    pieces: number;
  };
}

const initialMetalProgettiData : IMetalProgettiData = {
  "out": {
    "order_count": 0,
    "total_pieces": 0
  },
  "in": {
    "order_count": 0,
    "total_pieces": 0
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  selectedTab: string;
  filterAnchor: { currentTarget: {} } | undefined | HTMLDivElement;
  filters: IFilterItem[];
  itemToCleanFilters: IFilterItem[];
  itemToCleanFilterAnchor: { currentTarget: {} } | undefined | HTMLDivElement;
  sortingData: ISortingData;
  isSearchOpen:boolean;
  cardDetails: ICardOrderDet;
  cardDetailsModal: {
    section: string;
    orders: number;
    pieces: number;
  }[];
  cleaningDetails: ICleaningDue;
  searchText: string;
  regionIdQuery: string;
  regionIdQueryFirst: string;
  storeIdQuery: string;
  storeIdQueryFirst: string;
  groupIdQuery: string;
  groupIdQueryFirst: string;
  areaIdQuery: string;
  areaIdQueryFirst: string;
  modelDetails: {
    sections: string;
    orders: number;
    pieces: number;
  }[];
  isCardModal: boolean;
  plantStatusTilte: string;
  isCleanDueDet: boolean;
  chartDetails: {
    id: number;
    sections: string;
  }[];
  topItemToCleanDetails:{
    "name": string,
    "quantity": number,
    "total_pieces": number
  }[]
  topItemToCleanAllDetails:{
    "name": string,
    "quantity": number,
    "total_pieces": number
  }[]
  searchTopItemQuery:string,
  openTopItemModal: boolean,
  chartData:{
    dryCleanData:{
    "section": string,
    "orders": number,
    "pieces": number,
    "amount": string
    }[],
    laundryData:{
      "section": string,
      "orders": number,
      "pieces": number,
      "amount": string
    }[],
    pressingData:{
      "section": string,
      "orders": number,
      "pieces": number,
      "amount": string
    }[],
  },
  selectedStore: string
  selectedRegion: string
  regionId: string
  storeId: string
  groupId: string
  groupName: string
  rangeValue: Stats
  isFirstTimeCall: boolean
  activeIndex:number | null;
  activeIndexes: number[],
  isTooltipVisible: boolean;
  topItemListPageNo: number;
  chartLoader: {
    isLaundryLoading?: boolean,
    isDryLoading?: boolean,
    isPressingLoading?: boolean,
  };
  metalProgettiData: IMetalProgettiData;
  exportModalStatus: boolean;
  permissionStatus: DashboardPermissionStatus | null;
  isAppliedFilter: boolean;
  // Customizable Area End
}
interface SS {}

export default class PlantReportControlle extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCleaningDueDetailsMessageId: string = "";
  getTopItemToCleanDetailsMessageId: string = "";
  getCardOrderCountDetailsMessageId: string = "";
  getMetalProgettiDataMessageId: string = "";
  getCardDetailsModalMessageId: string = "";
  getCleaningDueDetailsModalMessageId: string = "";
  getLaundryDetailsMessageId: string = "";
  getDryCleanDetailsMessageId: string = "";
  getPressingDetailsMessageId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage),
      getCustomEnumName(CustomEnums.CustomActionReducers),
      getName(MessageEnum.LayoutDataMessage),
      getName(MessageEnum.SearchTextMessage)
    ];

    this.state = {
      exportModalStatus: false,
      activeIndex:null,
      activeIndexes: [],
      isTooltipVisible: false,
      chartLoader: {
        isLaundryLoading: true,
        isDryLoading: true,
        isPressingLoading: true,
       },
      isFirstTimeCall:false,
      isSearchOpen:false,
      rangeValue: {
        maxQuantity: 0,
        minQuantity:  0,
        maxTotalPieces:  0,
        minTotalPieces:  0,
      },
      topItemListPageNo:1,
      regionId: "",
      storeId: "",
      groupId: "",
      groupName: "",
      selectedTab: webConfigJSON.Tabs.Strings.plantReport,
      filterAnchor: undefined,
      searchText: "",
      regionIdQuery: "",
      regionIdQueryFirst: "",
      storeIdQuery: "",
      openTopItemModal: false,
      storeIdQueryFirst: "",
      groupIdQuery: "",
      groupIdQueryFirst : "",
      areaIdQuery: "",
      areaIdQueryFirst: "",
      cardDetailsModal: [],
      selectedStore: "",
      selectedRegion: "",
      plantStatusTilte: "",
      chartData: {
        dryCleanData: [],
        laundryData: [],
        pressingData: [],
      },
      chartDetails: [
        {
          id: 1,
          sections: "Laundry",
        },
        {
          id: 2,
          sections: "Dry Cleaning",
        },
        {
          id: 3,
          sections: "Pressing",
        },
      ],
      isCleanDueDet: false,
      filters: [
        {
          title: "Date",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          value: "today",
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last 7 days",
              value: "last_7_days",
            },
            {
              label: "Last 30 days",
              value: "last_30_days",
            },
            {
              label: "Specific Dates",
              value: "specific",
            },
          ],
        },
      ],
      itemToCleanFilters: [
        {
          title: "Quantity Range",
          type: "rangeslider",
          value: "",
          apiKey: "quantity",
          options: [
            {
              label: "Select Qty Range",
              value: "",
            },
          ],
        },
        {
          title: "Pieces Range",
          type: "rangeslider",
          value: "",
          apiKey: "pieces",
          options: [
            {
              label: "Select Pieces Range",
              value: "",
            },
          ],
        },
      ],
     itemToCleanFilterAnchor: undefined,
      cardDetails: {
        upcoming: {
          count: 0,
          pieces: 0,
        },
        received: {
          count: 0,
          pieces: 0,
        },
        loading_area: {
          count: 0,
          pieces: 0,
        },
        dispatched: {
          count: 0,
          pieces: 0,
        },
      },
      cleaningDetails: {
        due_today: {
          orders: 0,
          pieces: 0,
          completed: 0,
        },
        due_tomorrow: {
          orders: 0,
          pieces: 0,
          completed: 0,
        },
        over_due: {
          orders: 0,
          pieces: 0,
        },
      },
      sortingData: {
        items: "",
        quantity: "",
        pieces: "",
      },
      modelDetails: [
        {
          sections: "Laundry",
          orders: 2500,
          pieces: 25000,
        },
        {
          sections: "Dry Cleaning",
          orders: 2500,
          pieces: 25000,
        },
        {
          sections: "Pressing",
          orders: 2500,
          pieces: 25000,
        },
        {
          sections: "Blanket",
          orders: 2500,
          pieces: 25000,
        },
        {
          sections: "Shoes and Bags",
          orders: 2500,
          pieces: 25000,
        },
        {
          sections: "Carpets and Furniture",
          orders: 2500,
          pieces: 25000,
        },
      ],
      isCardModal: false,
      topItemToCleanDetails:[{
        "name": "",
        "quantity": 0,
        "total_pieces": 0
      }],
      topItemToCleanAllDetails:[{
        "name": "",
        "quantity": 0,
        "total_pieces": 0
      }],
      searchTopItemQuery: "",
      metalProgettiData: initialMetalProgettiData,
      permissionStatus: null,
      isAppliedFilter: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      activeIndexes: Array(this.state.chartDetails.length).fill(-1),
    });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.gatherLayoutData(message);
    this.retrieveSearchTextData(message);
    const requestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleMetalProgettiResponse(requestId, responseJson);
    this.handleCleaningDueRes(requestId, responseJson);
    this.handleCardOrderCountRes(requestId, responseJson);
    this.handleCardDetModalRes(requestId, responseJson);
    this.handleCleanDueDetModalRes(requestId, responseJson);
    this.handleTopItemsToCleanRes(requestId, responseJson);
    this.handleChartRes(requestId, responseJson);

    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );

      const { storeId,areaId, regionMultiId, groupId, regionNames, regionSelectAll } = recievedData
      this.setState({
        groupId: groupId, regionId: regionMultiId, 
        storeIdQuery:storeId ? `&store_ids=${storeId}`: "", storeId,
        storeIdQueryFirst: storeId? `?store_ids=${storeId}`: "",
        groupIdQuery: groupId ? `&group_ids=${groupId}`: "",
        groupIdQueryFirst: groupId ? `?group_ids=${groupId}`: "",
        areaIdQuery: areaId ? `&area_ids=${areaId}`: "",
        areaIdQueryFirst: areaId ? `?area_ids=${areaId}`: "",
        selectedRegion: handleDisplayRegion(regionMultiId, regionNames, regionSelectAll).textDisplayed,
        regionIdQuery: `&region_ids=${regionMultiId}`,
        regionIdQueryFirst: `?region_ids=${regionMultiId}`,
      }, () => {
        if (regionMultiId) {
          this.handleStorageFilter()
        }
      })
    }

    if (message.id === getCustomEnumName(CustomEnums.CustomActionReducers)) {
      const action = message.getData(getCustomEnumName(CustomEnums.CustomReducerAction));
      if (action === "UPDATE_METALPROGETTI") { this.throttledGetMetalProgettiData() }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  gatherLayoutData = (message: Message) => {
    if (message.id === getName(MessageEnum.LayoutDataMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.LayoutMessageData)
        );
        if (recievedData.userContext) {
          this.handleUserChange(recievedData.userContext)
        }
    }
  }

  retrieveSearchTextData = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
        const recievedData = message.getData(
            getName(MessageEnum.SearchMessageText)
        );
        if (recievedData) {
          this.handleSearch(recievedData.searchText)
        }
    }
  }

  getAllPlantReportData = () => {
    this.getCleaningDueDetails();
    this.getCardOrderCountDetails();
    this.getMetalProgettiData();
    this.getTopItemsToCleanDetails();
    this.getChartDetails(["Laundry","Dry Cleaning","Pressing"]);
    this.setState({isFirstTimeCall:true})
  }

  handleMomentDateFormat = (date: string, dateFormat: string) => {
    const formattedDate = moment(date).format(dateFormat);
    return formattedDate;
  };

  handleDateFilterParam = () => {
    const dateFilter = this.state.filters.find(
      (item) => item?.title === "Date"
    )?.value;
    let startDate, endDate;
    const today = moment().format("YYYY-MM-DD");
    let commonEndDate = today;
    let comomStartDate = moment().subtract(6, "days").format("YYYY-MM-DD");
    switch (dateFilter) {
      case "last7":
      case "last_7_days":
        endDate = commonEndDate;
        startDate = comomStartDate;
        break;
      case "today":
        endDate = today;
        startDate = today;
        break;
      case "specific":
        const dateFilter = this.state.filters.find(
          (item) => item?.title === "Date"
        )?.datevalue;
        if (dateFilter?.from && dateFilter?.to) {
          startDate = moment(dateFilter.from).format("YYYY-MM-DD");
          endDate = moment(dateFilter.to).format("YYYY-MM-DD");
        }
        break;
      case "last30":
      case "last_30_days":
        endDate = today;
        startDate = moment().subtract(29, "days").format("YYYY-MM-DD");
        break;
      default:
        startDate = moment().format("YYYY-MM-DD");
        endDate = today;
    }

    const dateFilterQueryVal =
      startDate && endDate
        ? `?start_date=${startDate}&end_date=${endDate}`
        : "";

    const endDateText = endDate
      ? this.handleMomentDateFormat(endDate, "DD-MM-YYYY")
      : moment().format("DD-MM-YYYY");
    const startDateText = startDate
      ? this.handleMomentDateFormat(startDate, "DD-MM-YYYY")
      : moment().subtract(6, "days").format("DD-MM-YYYY");
    return { dateFilterQueryVal, startDateText, endDateText };
  };

  getTopItemsToCleanDetails = () => {
    const qtyFilter = this.state.itemToCleanFilters.find(
      (item) => item.apiKey === "quantity"
    )?.value;
    const piecesFilter = this.state.itemToCleanFilters.find(
      (item) => item.apiKey === "pieces"
    )?.value;

    const dateFilterQuery = this.handleDateFilterParam().dateFilterQueryVal
    const qtyFilterVal =  (qtyFilter ? `&quantity_start=${qtyFilter[0]}&quantity_end=${qtyFilter[1]}` : "") 
    const piecesFilterVal =  (piecesFilter ? `&pieces_start=${piecesFilter[0]}&pieces_end=${piecesFilter[1]}` : "") 
    const requestMessage = makeApiMessage({
      url: `${webConfigJSON.getTopItemToCleanEndpoint}${dateFilterQuery}&page_no=${this.state.topItemListPageNo}${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.areaIdQuery}${this.state.groupIdQuery}` + qtyFilterVal + piecesFilterVal,
      method: "GET",
    });

    this.getTopItemToCleanDetailsMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getChartDetails = (Sectiondata: string[]) => {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQueryVal;
    Sectiondata.forEach((sections) => {
      const requestMessage = makeApiMessage({
        url: `${webConfigJSON.plantReportChartEndpoint}${dateFilterQuery}&service_name=${sections}${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.areaIdQuery}${this.state.groupIdQuery}`,
        method: "GET",
      });

      switch (sections) {
        case "Laundry":
          this.setState({chartLoader:{
            isLaundryLoading:true,
          }})
          this.getLaundryDetailsMessageId = requestMessage.messageId;
          break;
        case "Dry Cleaning":
          this.setState({chartLoader:{
            isDryLoading:true,
          }})
          this.getDryCleanDetailsMessageId = requestMessage.messageId;
          break;
        case "Pressing":
          this.setState({chartLoader:{
            isPressingLoading:true,
          }})
          this.getPressingDetailsMessageId = requestMessage.messageId;
          break;
        default:
          break;
      }
      runEngine.sendMessage(requestMessage.id, requestMessage);
    });
  };

  handleChartRes(requestId: string, response: { data: []; status: number }) {
    if (response) {
      if (requestId === this.getLaundryDetailsMessageId) {
        this.setState((prevState) => ({
          chartData: {
            ...prevState.chartData,
            laundryData: response.data,
          },
        }));
        this.setState({chartLoader:{
          isLaundryLoading:false,
        }})
      } else if (requestId === this.getDryCleanDetailsMessageId) {
        this.setState((prevState) => ({
          chartData: {
            ...prevState.chartData,
            dryCleanData: response.data,
            
          },
        }));
        this.setState({chartLoader:{
          isDryLoading:false,
        }})
      } else if (requestId === this.getPressingDetailsMessageId) {
        this.setState((prevState) => ({
          chartData: {
            ...prevState.chartData,
            pressingData: response.data,
          },
        }));
        this.setState({chartLoader:{
          isPressingLoading:false,
        }})
      }
    }
  }

  handleTopItemsToCleanRes(
    requestId: string,
    response: { data: []; status: number }
  ) {
    if (response && requestId === this.getTopItemToCleanDetailsMessageId) {
      let newPriceListToCopy = this.state.topItemListPageNo === 1 ? response.data : concat(this.state.topItemToCleanDetails, response.data)

      this.setState({
        topItemToCleanDetails: uniqBy(newPriceListToCopy, "name"),
        topItemToCleanAllDetails: response.data
      },()=>this.state.isFirstTimeCall && this.getMaxMinValue());
    }
  }

  getCleaningDueDetails = () => {
    const requestMessage = makeApiMessage({
      url: `${webConfigJSON.getCleaningDueEndpoint}${this.state.regionIdQueryFirst}${this.state.groupIdQuery}${this.state.storeIdQuery}${this.state.areaIdQuery}`,
      method: "GET",
    });

    this.getCleaningDueDetailsMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCleaningDueRes(requestId: string, response: ICleaningDue) {
    if (response && requestId === this.getCleaningDueDetailsMessageId) {
      this.setState({
        cleaningDetails: response,
      });
    }
  }

  getCardOrderCountDetails = () => {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQueryVal;

    const requestMessage = makeApiMessage({
      url: `${webConfigJSON.getCardOrderCountEndpoint}${dateFilterQuery}${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.areaIdQuery}${this.state.groupIdQuery}`,
      method: "GET",
    });

    this.getCardOrderCountDetailsMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCardOrderCountRes(requestId: string, response: ICardOrderDet) {
    if (response && requestId === this.getCardOrderCountDetailsMessageId) {
      this.setState({
        cardDetails: response,
      });
    }
  }

  getMetalProgettiData = () => {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQueryVal;

    const requestMessage = makeApiMessage({
      url: `${webConfigJSON.metalProgettiApi}${dateFilterQuery}${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.areaIdQuery}${this.state.groupIdQuery}`,
      method: "GET",
    });

    this.getMetalProgettiDataMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  throttledGetMetalProgettiData = throttle(() => { this.getMetalProgettiData() }, 2000, {leading: false, trailing: true})

  handleMetalProgettiResponse = (requestId: string, response?: {data?: IMetalProgettiData}) => {
    if (response && requestId === this.getMetalProgettiDataMessageId) {
      const newMetalProgettiData = response?.data?.in ? response.data : initialMetalProgettiData
      this.setState((prev) => ({
        metalProgettiData: newMetalProgettiData,
        cardDetailsModal: (prev.isCardModal && prev.plantStatusTilte === "metal_progetti") ? 
        [
          {
            section: "Sent (No of .in Files)",
            orders: prev.metalProgettiData.in.order_count,
            pieces: prev.metalProgettiData.in.total_pieces
          },
          {
            section: "Completed (No of .out Files)",
            orders: prev.metalProgettiData.out.order_count,
            pieces: prev.metalProgettiData.out.total_pieces
          }
        ] : prev.cardDetailsModal
      }))
    }
  }

  handleTabSeletion = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ selectedTab: newValue });
  };

  handleFilterButton = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({
      filterAnchor: event.currentTarget,
    });
  };

  handleItemToCleanFilterButton = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    this.setState({
      itemToCleanFilterAnchor: event.currentTarget,
    });
  };

  handleItemToCleanCloseFilterButton = () => {
    this.setState({
      itemToCleanFilterAnchor: undefined,
    });
  };


  handleCloseFilterButton = () => {
    this.setState({
      filterAnchor: undefined,
    });
  };

  handleFilterChange = (filters: IFilter[]) => {
    if (checkIsFilterApplied(filters)) {
      localStorage.setItem("filter_value", JSON.stringify(filters));
    } else {
      localStorage.removeItem("filter_value");
    };
    this.setState({
      isAppliedFilter: checkIsFilterApplied(filters),
      filters,
      isFirstTimeCall:true
    },()=>{
      this.getCardOrderCountDetails()
      this.getTopItemsToCleanDetails()
      this.getChartDetails(["Laundry","Dry Cleaning","Pressing"]);
      this.getMetalProgettiData()
    });
  };


  handleFilterChangeForItemtoClean = (filters: IFilter[]) => {
    this.setState({
      itemToCleanFilters: filters, topItemListPageNo:1
    },this.getTopItemsToCleanDetails);
  };

  sortingProps = {
    width: "18%",
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  handleSearch = (value: string) => {
    this.setState({ searchText: value });
  };

  handleRegionChange = (value: string, regionName: string, isSelectedAll?: boolean) => {
    const regionText = handleDisplayRegion(value, regionName, isSelectedAll).textDisplayed
    this.setState({
    
      regionId: value,
      selectedRegion: regionText,
      regionIdQuery: `&region_id=${value}`,
    },() => {
      this.getCardOrderCountDetails();
      this.getTopItemsToCleanDetails();
      this.getCleaningDueDetails();
      this.getChartDetails(["Laundry", "Dry Cleaning", "Pressing"]);
    });
  };



  getTypeOfOrderItemTable(index: number) {
    if (index === 0) {
      return "left";
    } else if (index === 1 && this.state.isCleanDueDet) {
      return "right";
    } else if (index === 2) {
      return "right";
    } else {
      return "middle";
    }
  }

  getTableRowColumnsForOrderDetails() {
    if (this.state.isCleanDueDet) {
      return "2fr 1fr";
    } else {
      return "2fr 1fr 1fr";
    }
  }

  getCardDetailsModalAPI = (plantStatus: string) => {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQueryVal;

    const requestMessage = makeApiMessage({
      url:
        webConfigJSON.plantRepCardDetModal +
        `${dateFilterQuery}${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.areaIdQuery}${this.state.groupIdQuery}&plant_status=${plantStatus}`,
      method: "GET",
    });

    this.getCardDetailsModalMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCleaningDueDetailsModalAPI = (plantStatus: string) => {
    const requestMessage = makeApiMessage({
      url:
        webConfigJSON.getCleanDueModalDetEndpoint +
        `?cleaning_due=${plantStatus}${this.state.regionIdQuery}${this.state.storeIdQuery}${this.state.areaIdQuery}${this.state.groupIdQuery}`,
      method: "GET",
    });

    this.getCleaningDueDetailsModalMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCleanDueDetModalRes(
    requestId: string,
    response: { status: number; data: [] }
  ) {
    if (requestId === this.getCleaningDueDetailsModalMessageId) {
      this.setState({
        cardDetailsModal: response.data,
      });
    }
  }

  handleCardDetModalRes(
    requestId: string,
    response: { status: number; data: [] }
  ) {
    if (requestId === this.getCardDetailsModalMessageId) {
      this.setState({
        cardDetailsModal: response.data,
      });
    }
  }

  handleOpenDetailsModal = (plantStatus: string) => {
    this.getCardDetailsModalAPI(plantStatus);
    this.setState({ isCardModal: true, plantStatusTilte: plantStatus });
  };

  handleOpenCleaningDueDetailsModal = (plantStatus: string) => {
    this.getCleaningDueDetailsModalAPI(plantStatus);
    this.setState({
      isCardModal: true,
      plantStatusTilte: plantStatus,
      isCleanDueDet: true,
    });
  };

  handleOpenMetalProgettiModal = () => {
    this.setState((prev) => ({
      isCardModal: true,
      plantStatusTilte: "metal_progetti",
      isCleanDueDet: true,
      cardDetailsModal: [
        {
          section: "Sent (No of .in Files)",
          orders: prev.metalProgettiData.in.order_count,
          pieces: prev.metalProgettiData.in.total_pieces
        },
        {
          section: "Completed (No of .out Files)",
          orders: prev.metalProgettiData.out.order_count,
          pieces: prev.metalProgettiData.out.total_pieces
        }
      ]
    }));
  };

  handleCloseCardModal = () => {
    this.setState({ isCardModal: false, isCleanDueDet: false });
  };

  handleCloseTopItemModal = () => {
    this.setState({ openTopItemModal: false,topItemListPageNo:1 });
  };

  handleSearchCleanItem = (event: React.ChangeEvent<{ value: string }>) => {
    const value: string = event.target.value;
    this.setState({ searchTopItemQuery: value });
  };

  getPeriodDate = (startPeriodDate: string, endPeriodDate: string) => {
    const periodDate = `${startPeriodDate}_${endPeriodDate}`;
    return periodDate;
  };

  handleOpenItemModal = () => {
    this.setState({ openTopItemModal: true, itemToCleanFilterAnchor: undefined});
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.dashboardPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForDashboardPermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    });

  };

  getSortingProps() {
    return {
      sortingData: this.state.sortingData,
      onChange: (data: ISortingData) => {
        this.setState({ sortingData: data }, this.sortData);
      },
    };
  }

  sortData() {
    const { sortingData, topItemToCleanDetails } = this.state;
    let sortedData = [...topItemToCleanDetails];

    if (sortingData.items) {
      sortedData.sort((a, b) =>
        sortingData.items === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name)
      );
    }

    if (sortingData.quantity) {
      sortedData.sort((a, b) =>
        sortingData.quantity === "asc"
          ? a.quantity - b.quantity
          : b.quantity - a.quantity
      );
    }

    if (sortingData.pieces) {
      sortedData.sort((a, b) =>
        sortingData.pieces === "asc"
          ? a.total_pieces - b.total_pieces
          : b.total_pieces - a.total_pieces
      );
    }

    this.setState({ topItemToCleanDetails: sortedData });
  }


  handleMouseActiveEnter = (event:React.MouseEvent,index:number,chartindex:number) => {
    const newActiveIndexes = [...this.state.activeIndexes];
    newActiveIndexes[chartindex] = index;
    this.setState({ activeIndexes: newActiveIndexes });
  };

  handleMouseActiveLeave = (chartindex:number) => {
    const newActiveIndexes = [...this.state.activeIndexes];
    newActiveIndexes[chartindex] = -1;
    this.setState({ activeIndexes: newActiveIndexes });
  };
  getMaxMinValue = () => {
    if(this.state.topItemToCleanAllDetails.length > 1){
      let result:Stats = this.state.topItemToCleanAllDetails.reduce<Stats>(
       (acc, item) => {
         if (item.quantity > acc.maxQuantity) acc.maxQuantity = item.quantity;
         if (item.quantity < acc.minQuantity) acc.minQuantity = item.quantity;
         if (item.total_pieces > acc.maxTotalPieces) acc.maxTotalPieces = item.total_pieces;
         if (item.total_pieces < acc.minTotalPieces) acc.minTotalPieces = item.total_pieces;
         return acc;
       },
       {
         maxQuantity: -Infinity,
         minQuantity: Infinity,
         maxTotalPieces: -Infinity,
         minTotalPieces: Infinity
       }
     );
     this.setState({rangeValue:result,isFirstTimeCall:false})
    }
  }

  handleSerchOpen = () => {
    this.setState({isSearchOpen:!this.state.isSearchOpen})
  }

  handleItemScroll = (event: React.SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1.3 && this.state.topItemToCleanAllDetails.length !== 0) {
      this.setState((prev) => ({ topItemListPageNo: prev.topItemListPageNo + 1 }), () => {
        this.getTopItemsToCleanDetails()
      })
    }
  }

  handleExportModal = () => {
    this.setState(prevState => ({
      exportModalStatus: !prevState.exportModalStatus
    }));
  }

  handleExportConfirm = async (methodName: string) => {
    if (methodName === "pdf") {
      const element = document.getElementById("print-wrapper-plantReport") as HTMLElement
      await html2pdf(element, {
        pagebreak: { mode: ['css'] },
        filename: "plantReport-dashboard.pdf",
        margin: 24,
        jsPDF: {
          format: [1440, 1440],
          unit: "px"
        }
      })
      this.setState({ exportModalStatus: false })
    }
  }

  handleStorageFilter = () => {
    const applied_profession_filter = localStorage.getItem("filter_value");
    if (applied_profession_filter) {
      this.setState(
        {
          filters: JSON.parse(applied_profession_filter),
          isAppliedFilter: checkIsFilterApplied(
            JSON.parse(applied_profession_filter)
          ),
        },
        () => {
          this.getAllPlantReportData()
        }
      );
    } else {
      this.getAllPlantReportData()
    }
  };

  // Customizable Area End
}